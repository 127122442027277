import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { app_object_type } from "../types/types";
import Ui from "./Ui";

const Simulation = () => {

    const app_id = useParams().app_id;
    const [app, set_app] = useState<app_object_type | null>(null);

    useEffect(() => {
        if (!app_id) {
            console.log("APP ID NOT FOUND");
            return;
        }

        let counter = 1;

        const id = setInterval(() => {
            counter = counter + 1;
            if (counter === 60) {
                console.log("30 seconds passed, clearing interval"); // End after 30 seconds like in module
                clearInterval(id);
                return;
            }
            if (!(window.broken && window.broken.offline)) {
                console.log("window.broken.offline not found, waiting ...");
                return;
            }
            // Assign current in broken
            window.broken.current = window.broken.offline;
            const window_app = window.broken.offline?.app;
            if (window_app) {
                set_app(window_app);
                // SET TITLE AND LOGO
                document.title = window_app.name + " Simulation";
                let link: any = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = window_app.logo_url;
                clearInterval(id); // End when app is found
            }
        }, 500);
    }, [])

    return (
        <div>
            {!app && (
                <h1 className="p-2">Loading ...</h1>
            )}

            {app && (
                <Ui app={app} />
            )}
        </div>
    )
}

export default Simulation;