import { useEffect, useState } from "react";
import { JsonToTable } from "react-json-to-table";
import { useParams } from "react-router-dom";
import { create_random_user } from "../fake-data-generator/user-data-generator";
import { GET_GS, global_state_use, SET_GS } from "../global_state";
import { test_user } from "../stubs/user_local";
import { app_object_type, zod_app_object } from "../types/types";
import Input from "./General/Input";

const Creator = () => {

    const created_users = global_state_use(s => s.created_users);
    const [roles, set_roles] = useState<string[]>([]);
    const [name, set_name] = useState<string>('');
    const [email, set_email] = useState<string>('');
    const [img_url, set_img_url] = useState<string>('');
    const [role, set_role] = useState<string>('');
    const [org, set_org] = useState<string>('');
    const [team, set_team] = useState<string>('');

    const app_id = useParams().app_id;
    const [app_retrieval_status, set_app_retrieval_status] = useState<boolean>(false);
    const app = global_state_use(s => s.app);

    useEffect(() => {
        if (!app_id) return;
        set_app_retrieval_status(true);
        get_all_roles_in_app();

        const modified_user_with_app = JSON.parse(test_user);
        modified_user_with_app.app_id = app_id;
    }, [])

    const get_all_roles_in_app = () => {
        const app_object = GET_GS().app;
        const errors: string[] = [];
        const parsed_app_object = zod_app_object.safeParse(app_object);

        if (!parsed_app_object.success) {
            errors.push(parsed_app_object.error.toString());
            const res = {
                success: false,
                errors
            }
            return res;
        }
        const app: app_object_type = parsed_app_object.data;

        const authz = app.authz;
        const roles: string[] = [];

        roles.push(...Object.keys(authz.app.role));
        for (let [m, v] of Object.entries(authz.models)) {
            roles.push(...Object.keys(v.role));
        }

        set_roles([... new Set(roles)]);
    }

    return (
        <div className="p-2">
            {!app_retrieval_status && (
                <h1>Fetching app details...</h1>
            )}
            {app_retrieval_status && !app && (
                <h1>App Details not found in iframe</h1>
            )}
            {app_retrieval_status && app && (
                <div className="flex flex-col gap-3">
                    <div className="p-2 border border-slate-400 cursor-pointer" onClick={() => {
                        if (!app_id) {
                            console.log('no app id');
                            return;
                        }
                        const user_new = create_random_user(app_id);
                        SET_GS({
                            created_users: [...created_users, user_new]
                        })
                    }}>
                        Create random user
                    </div>

                    <div className="p-2 border border-slate-400">
                        Create your own user
                        <div className="flex gap-2 p-2">
                            Name
                            <Input
                                prev_value=""
                                on_enter={(new_value) => {
                                    set_name(new_value)
                                }}
                            />
                        </div>
                        <div className="flex gap-2 p-2">
                            Email
                            <Input
                                prev_value=""
                                on_enter={(new_value) => {
                                    set_email(new_value)
                                }}
                            />
                        </div>
                        <div className="flex gap-2 p-2">
                            Organisation
                            <Input
                                prev_value=""
                                on_enter={(new_value) => {
                                    set_org(new_value)
                                }}
                            />
                        </div>
                        <div className="flex gap-2 p-2">
                            Team
                            <Input
                                prev_value=""
                                on_enter={(new_value) => {
                                    set_team(new_value)
                                }}
                            />
                        </div>
                        <div className="flex gap-2 p-2">
                            Image
                            <Input
                                prev_value=""
                                on_enter={(new_value) => {
                                    set_img_url(new_value)
                                }}
                            />
                        </div>
                        <div className="flex gap-2 p-2">
                            Role
                            <select className="w-full p-2 rounded-full" onChange={(e) => {
                                set_role(e.target.value);
                            }}>
                                {roles.map(r => {
                                    return (
                                        <option key={r} value={r}>{r}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="w-20 p-2 border border-slate-600 m-auto cursor-pointer" onClick={() => {
                            const id = window.broken.utils.ulid();
                            if (!app_id || !name || !email || !img_url || !role || !org || !team) {
                                console.log('Missing DATA');
                                return;
                            }
                            SET_GS({
                                created_users: [...created_users, {
                                    id,
                                    name: name,
                                    email: email,
                                    image_url: img_url,
                                    role: role,
                                    universe: 0,
                                    level: 1,
                                    app_id,
                                    created_at: Date.now(),
                                    org: org,
                                    team: team,
                                    verified_at: Date.now(),
                                    modified_at: Date.now(),
                                }]
                            })
                        }}>
                            Create
                        </div>
                    </div>

                    {created_users.length > 0 && (
                        <div className="w-full overflow-auto">
                            <JsonToTable json={created_users} />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Creator;