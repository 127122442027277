import { user_type } from '../types/types';
import { generate_date, generate_email, generate_image_url, generate_name, generate_number, generate_role, generate_serial, generate_word } from './basic-data-generator';

export const create_random_user = (app_id: string): user_type => {
    return {
        id: generate_serial(),
        name: generate_name(),
        email: generate_email(),
        image_url: generate_image_url(),
        role: generate_role(),
        universe: 0,
        level: 1,
        app_id,
        created_at: generate_date(),
        org: generate_word(),
        team: generate_word(),
        verified_at: generate_date(),
        modified_at: generate_date(),
    }
}

export const create_random_users = (app_id: string, count: number): user_type[] => {
    return Array.from({ length: count }, () => create_random_user(app_id))
}

export const ten_users: user_type[] = [
    {
        "id": "01gvfp1e5zkb3ey7vbfzfyx4ct",
        "name": "Magdalena",
        "email": "Terry36@yahoo.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/956.jpg",
        "role": "editor",
        "universe": 47675,
        "level": 92051,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1668540595525,
        "org": "apud",
        "team": "Saint",
        "verified_at": 1672422879626,
        "modified_at": 1674963285576
    },
    {
        "id": "01gvfp1e60nz3me3re31h3e985",
        "name": "Reyna",
        "email": "Raleigh24@yahoo.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/717.jpg",
        "role": "admin",
        "universe": 16199,
        "level": 4160,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1676803177333,
        "org": "payment",
        "team": "Southeast",
        "verified_at": 1660699266502,
        "modified_at": 1675298795072
    },
    {
        "id": "01gvfp1e60x1yxyrwwjc079q8r",
        "name": "Bernie",
        "email": "Rowena62@hotmail.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/716.jpg",
        "role": "user",
        "universe": 46315,
        "level": 87828,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1661013810001,
        "org": "Folk",
        "team": "ethnicity",
        "verified_at": 1657611909311,
        "modified_at": 1672432328273
    },
    {
        "id": "01gvfp1e61h3x5mjefyt0x0d9f",
        "name": "Cindy",
        "email": "Eleazar46@hotmail.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/457.jpg",
        "role": "editor",
        "universe": 99909,
        "level": 35641,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1649300846052,
        "org": "deeply",
        "team": "quantify",
        "verified_at": 1665097904321,
        "modified_at": 1651332445542
    },
    {
        "id": "01gvfp1e61e1t4e061mj1zxn26",
        "name": "Anissa",
        "email": "Zoila48@hotmail.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/10.jpg",
        "role": "viewer",
        "universe": 65283,
        "level": 74211,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1670828301401,
        "org": "digital",
        "team": "users",
        "verified_at": 1655455941320,
        "modified_at": 1656378850643
    },
    {
        "id": "01gvfp1e61zgvdf26dgq30s85x",
        "name": "Emily",
        "email": "Scottie.Wehner@hotmail.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/894.jpg",
        "role": "viewer",
        "universe": 92359,
        "level": 84516,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1663367616730,
        "org": "becquerel",
        "team": "fuchsia",
        "verified_at": 1661763754985,
        "modified_at": 1672699481726
    },
    {
        "id": "01gvfp1e61qvhwmtj9et48yh66",
        "name": "Thea",
        "email": "Jack.Kuphal88@hotmail.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/226.jpg",
        "role": "viewer",
        "universe": 27503,
        "level": 9032,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1652954453614,
        "org": "Ohio",
        "team": "ignorance",
        "verified_at": 1648372349962,
        "modified_at": 1674047139790
    },
    {
        "id": "01gvfp1e614ykcty0e0rham71d",
        "name": "Jarred",
        "email": "Torrance.Wilderman92@yahoo.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/591.jpg",
        "role": "admin",
        "universe": 15625,
        "level": 10930,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1675386810947,
        "org": "redound",
        "team": "whenever",
        "verified_at": 1654727194418,
        "modified_at": 1662256223464
    },
    {
        "id": "01gvfp1e62r5v8wvmynj088h2n",
        "name": "Rubye",
        "email": "Clyde_Rau@yahoo.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1152.jpg",
        "role": "editor",
        "universe": 6126,
        "level": 5971,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1659044495334,
        "org": "Chips",
        "team": "gold",
        "verified_at": 1662548132013,
        "modified_at": 1658066627517
    },
    {
        "id": "01gvfp1e62dkbvmw69fxf66rgt",
        "name": "Brian",
        "email": "Candelario.Yundt55@hotmail.com",
        "image_url": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/553.jpg",
        "role": "viewer",
        "universe": 21276,
        "level": 85320,
        "app_id": "01gvfbrvgypbk820cfk6dzkybq",
        "created_at": 1660125960262,
        "org": "Bicycle",
        "team": "Hazel",
        "verified_at": 1677660351554,
        "modified_at": 1651751135898
    }
]

export default {};