import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LandingPage from './Components/LandingPage';
import Simulation from './Components/Simulation';
import Creator from './Components/Creator';

const App = () => {

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/:app_id" element={<Simulation />} />
				<Route path="/:app_id/creator" element={<Creator />} />
				<Route path="*" element={<LandingPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;