import { useEffect, useState } from "react";
import { app_object_type } from "../types/types";
import { init_DS } from "../global_state";

interface UiProps {
    app: app_object_type
}


const render_preview = (src: {code: string, css: string}) => {
    const pel = document.getElementById("test-tw-css");
    if (pel) pel.remove();


    const el = document.createElement("style");
    el.id = "test-tw-css";
    el.textContent = src.css;
    document.head.appendChild(el);


    // @ts-ignore
    if (Object.hasOwn(window, "broken_preview")) {
        // @ts-ignore
        window.broken_preview(src.code);
        return true;
    }
    
    return false;
}

let last_src: {code: string, css: string}|null = null;
const check_continous = () => {
    let timer_id: any = null;
    timer_id = setInterval(() => {
        if(!last_src){
            clearInterval(timer_id);
            return;
        }

        if(render_preview(last_src)){
            clearInterval(timer_id);
            last_src = null;
        }
    }, 100)
}

const render_ui_src = (src: {code: string, css: string}) =>{
    if(render_preview(src)){
        // console.log("RENDER SUCCESS");
    }
    else{
        // window.broken_preview not loaded yet. Depends on network
        console.log("window.broken_preview not loaded yet. Depends on network");
        last_src = src;
        check_continous();
    }
}

const Ui = (props: UiProps) => {

    const { app } = props;
    const [ui_src, set_ui_src] = useState<{code: string, css: string}>({code: "", css: ""});

    useEffect(() => {
        // Datascript init
        init_DS(app.models);

        // Get ui source code from iframe
        if (!window.broken.offline) return console.warn("window.broken.offline not found");
        const IFS = window.broken?.offline?.iframe_storage;
        const code_id = `${app.id}_ui_code`;
        const css_id = `${app.id}_ui_css`;

        IFS.get(code_id, (err: any, code: any) => {
            if (err) {
                console.error(err);
                return;
            }


            
            IFS.get(css_id, (err: any, css: any) => {
                if (err) {
                    console.error(err);
                    return;
                }
    
                set_ui_src({code, css});
            });
        });
    }, []);


    useEffect(()=>{
        if(!ui_src) return;

        render_ui_src(ui_src);
    }, [ui_src])

    return (
        <div id="tw-cdn">
            <div id="broken_preview"></div>
        </div>
    )
}

export default Ui;