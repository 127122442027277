import { useState } from "react";

interface InputProps {
    prev_value: string
    on_enter: (new_value: string) => void
    placeholder?: string
}

const Input = (props: InputProps) => {
    // Pass argument of a function that returns a boolean upon sanity check of given input
    // Callback function returns the latest value of input if the sanity check is passed
    // Takes an argument of all the events that needs to be happenned on click

    const { prev_value, on_enter, placeholder } = props;
    const [new_value, set_new_value] = useState<string>(prev_value);

    return (
        <div className="inline-flex gap-2 w-full items-center">
            <input itemID="name_input" className="w-full h-10 appearance-none line-clamp-1 bg-zinc-300 dark:bg-zinc-700 text-black dark:text-zinc-100 placeholder-zinc-500 focus:text-zinc-900 dark:focus:text-zinc-50 hover:text-zinc-900 font-medium dark:font-normal dark:hover:text-zinc-50 hover:outline-zinc-400 outline-none rounded-full focus:bg-zinc-300/90 dark:focus:bg-zinc-800 focus:outline-teal-400 py-2.5 px-3 text-base truncate pr-10" placeholder={placeholder} type="text" name="" id="" value={new_value} onChange={(e: any) => {
                set_new_value(e.target.value);
            }} onKeyDown={(e) => {
                if (e.key === "Enter") {
                    // if (!new_value) return;
                    (e.target as HTMLInputElement).disabled = true;

                    on_enter(new_value);
                }
                else if (e.key === "Escape") {
                    set_new_value(prev_value);
                    (e.target as HTMLInputElement).disabled = true;
                }
                else {
                    return;
                }
            }} onClick={(e) => {
                (e.target as HTMLInputElement).disabled = false;
                (e.target as HTMLInputElement).focus();
            }} />
        </div>
    )
}

export default Input;