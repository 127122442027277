import { persist } from 'zustand/middleware';
import { create } from 'zustand';
import { app_object_type, user_type, model_type, tx_meta, OBJECT_TYPE } from './types/types';

const MAX_DB_HISTORY = 100;


export interface globalStateType {
	app: app_object_type | null
    role :  "admin"|"viewer"|"editor"|"user"
    user: user_type | null
	created_users: user_type[];
    model :  model_type|null
    model__id: number
    user__id: number

	db_tx: { tx: number, meta: tx_meta },
	db_tx_new: (meta?: tx_meta) => void,

	db_list: any[],
	db_index: number,
	undo: () => boolean,
	redo: () => boolean,
	set_new_db: (db: any) => boolean
}

export const global_state_use = create(
	persist<globalStateType>(
		(set, get) => (
			{
                app: null,
                role: "admin",
                user: null,
				created_users: [],
                model: null,
                model__id: 0,
                user__id: 0,
				
				db_tx: {
					tx: 0, meta: {
						origin: '',
						operation: ''
					}
				},
				db_tx_new: (meta) => {
					const s = get().db_tx;
					set({
						db_tx: {
							tx: s.tx + 1,
							meta: meta || {
								origin: '',
								operation: ''
							}
						}
					});
				},


				db_list: [],
				db_index: -1,
				undo: () => {
					const s = get();
					const i = s.db_index;
					if (i <= 0) return false;

					const db = s.db_list.at(i - 1);
					if (!db) return false;

					// set({db: db, db_index: i-1});
					return true;
				},
				redo() {
					const s = get();
					const i = s.db_index;
					if (i >= (s.db_list.length - 1)) return false;

					const db = s.db_list.at(i + 1);
					if (!db) return false;

					// set({db: db, db_index: i+1});
					return true;
				},

				set_new_db(db) {
					const s = get();
					const i = s.db_index;

					if (i >= (MAX_DB_HISTORY - 1)) {
						s.db_list.shift();  // remove earliest
						s.db_list.push(db); // add new
						// index remain same
						// set({db: db, db_list: s.db_list.slice(0, MAX_DB_HISTORY-1)});
					}
					else {
						const new_list = s.db_list.slice(0, i); // discard all redos
						new_list.push(db); // add new
						// i++
						// set({db: db, db_list: new_list, db_index: i+1})
					}

					return true;
				}
			}
		),
		{
			name: 'global_state', // unique name
		}
	)
);



export const SET_GS = global_state_use.setState;
export const GET_GS = global_state_use.getState;


let datascript: any = null;
if(window.broken && window.broken.offline) datascript = window.broken.offline.ds.datascript;

let DS: any = datascript;
let conn: any = null;
let db: any = null;

if(window.broken && window.broken.offline) db = window.broken.offline.ds.db;


export const create_schema = (models: model_type[]): OBJECT_TYPE<any> => {
	const schema: OBJECT_TYPE<any> = {};
	for (let model of models) {
		const model_id = model.id;
		for (let p of model.props) {
			if (p.is_many === true) {
				schema[`${model_id}:${p.name}`] = {}
				schema[`${model_id}:${p.name}`][":db/cardinality"] = ":db.cardinality/many"
			}
			if (p.is_unique === true) {
				if (!schema[`${model_id}:${p.name}`]) {
					schema[`${model_id}:${p.name}`] = {}
				}
				schema[`${model_id}:${p.name}`][":db/unique"] = ":db.unique/value"
			}
		}
		schema[`${model_id}:${model.primarykey}`] = {}
		schema[`${model_id}:${model.primarykey}`][":db/unique"] = ":db.unique/identity"
	}
	return schema;
}

const init_db = (models: model_type[]) => {
	if (!DS) return;

	console.log('init db');

	const schema: OBJECT_TYPE<any> = create_schema(models);

	db = DS.empty_db(schema);
	conn = DS.conn_from_db(db);

	if (!window.broken.offline) return;
	window.broken.offline.ds.db = db;
	return db;
};

export const init_DS = (models: model_type[]) => {
	if (!DS) {
		// @ts-ignore
		DS = datascript;

		if (!window.broken.offline) return;
		window.broken.offline.ds.datascript = DS;
	}
	else {
		if (!window.broken.offline) return;
		window.broken.offline.ds.datascript = DS;
	}

	init_db(models);
};

const tx_queue: { tx: any[], meta: tx_meta }[] = [];
export const transact_DS = (tx: any[], meta: tx_meta) => {
	if (!DS || !db || !conn) return;

	tx_queue.push({ tx, meta });
};

let has_new_datoms = false;
setInterval(() => {
	if (!tx_queue.length) return;

	for (const d of tx_queue.splice(0, tx_queue.length)) {
		if (!d) continue;

		const tx_report = DS.transact(
			conn,
			d.tx,
			d.meta || {
				origin: '',
				operation: ''
			}
		);

		if (tx_report) {
			has_new_datoms = true; // for saving data

			db = tx_report.db_after;

			if (!window.broken.offline) return;
			window.broken.offline.ds.db = db;
		}
	}

}, 10);

export const save_to_localstorage = () => {
	if (!has_new_datoms) return;

	if (!DS || !conn || !db) return;

	const datoms = DS.datoms(db, ':eavt');
	console.log('saving datoms process initiated with length: ', datoms.length);

	const datom_str = JSON.stringify(datoms);
	localStorage.setItem('app_data_datoms', datom_str);

	has_new_datoms = false;
};

setInterval(() => {
	save_to_localstorage();
}, 1000);

export const get_DS_conn = () => {
	if (!DS) return;
	if (conn) return conn;

	// const db = global_state_use.getState().db;
	if (!db) return console.log('no db created yet in global state');

	conn = DS.conn_from_db(db);
	return conn;
};

export const query_DS = (q: string) => {
	if (!DS || !db || !conn) return;

	return DS.q(q, db);
};

export const pull_DS = (q: string, id: number) => {
	if (!DS || !db || !conn) return;

	return DS.pull(db, q, id);
};

export const rule_DS = (q: string, r: string) => {
	if (!DS || !db || !conn) return;

	return DS.q(q, db, r);
};

export const query_DS_get_eav = (q: string) => {
	if (!DS || !db || !conn) return;


	// const q = '[:find ?e ?a ?v :in $ :where [?e ?a ?v] [?e "cat" "attr"]]';
	const r = DS.q(q, db);

	const ets = prepare_entities(r);
	return ets;
};

export const prepare_entities = (r: any): Array<any> => {
	const els: { [key: number]: any } = {};
	if (Array.isArray(r)) {
		for (const da of r) {
			let d = els[da[0]];
			if (!d) {
				d = { _id: da[0] };
			}
			d[da[1]] = da[2]; // k:v
			els[da[0]] = d;

		}
	}
	return Object.values(els);
};

export const DS_unzip_one = (arr: any, n: number): string[] => {
	if (arr && Array.isArray(arr) && arr.length) {
		return arr.map(a => {
			if (a && Array.isArray(a) && a.length) {
				return a[0];
			}
		});
	}
	return [];
};