
const faker = {
    random: {
        word: () => { return 'word'; },
        
    },
    lorem: {
        sentence: () => { return 'sentence'; },
        paragraph: () => { return 'paragraph'; },
    },
    internet: {
        url: () => { return 'url'; },
        email: () => { return 'email'; },
    },
    datatype: {
        number: () => { return 1; },
        boolean: () => { return true; },
    },
    date: {
        past: () => { return new Date(); },
    },
    name: {
        firstName: () => { return 'firstName'; },
    },
    image: {
        avatar: () => { return 'avatar'; },
    },
    helpers: {
        arrayElement: (arr: any[]) => { return arr[0]; },
    },
}
export const generate_serial = () => {
    return window.broken.utils.ulid();
}

export const generate_word = () => {
    return faker.random.word();
}

export const generate_text = () => {
    return faker.lorem.sentence();
}

export const generate_paragraph = () => {
    return faker.lorem.paragraph();
}

export const generate_url = () => {
    return faker.internet.url();
}

export const generate_number = () => {
    return faker.datatype.number();
}

export const generate_boolean = () => {
    return faker.datatype.boolean();
}

export const generate_date = () => {
    return faker.date.past().getTime();
}

export const generate_name = () => {
    return faker.name.firstName();
}

export const generate_email = () => {
    return faker.internet.email();
}

export const generate_image_url = () => {
    return faker.image.avatar();
}

export const generate_role = () => {
    return faker.helpers.arrayElement(['admin', 'user', 'viewer', 'editor']);
}

export default {};